import { useTranslation } from "react-i18next"
import validateForm from "../../validation";
import { useRef, useState } from "react";

const ContactForm = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [formData, setFormData] = useState({
    message: "",
    to: "info@appsterdevelopment.com"
  });
  const [formSubmited, setFormSubmited] = useState(false);

  const sendContactMessage = (event) => {
    if (!validateForm(formRef.current)) return;
    const _formData = new FormData();
    for (var i in formData) {
      _formData.append(i, formData[i]);
    }
    fetch('https://contactform.appsterdevelopment.co.rs/mail_cv.php', { method: 'POST', body: _formData }).then(res => res.text());
    setFormSubmited(true);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact-form bg-white p-80 rmb-55 wow fadeInRight delay-0-2s">
      <div className="section-title mb-30">
      </div>
      {!formSubmited && (
        <div
          onSubmit={(e) => e.preventDefault()}
          className="form-style-one"
          action="#"
          name="contact-form"
          ref={formRef}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('full_name')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  id="phone"
                  name="phoneNumber"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('phone_number')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="email"
                  id="emailid"
                  name="email"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('email')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('subject')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows={3}
                  placeholder={`*${t('message')}`}
                  required
                  defaultValue={""}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-xl-12">
              <div className="form-group mb-0">
                <button type="submit" className="theme-btn style-two mt-15 w-100" onClick={sendContactMessage}>{t('send_message')}</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {formSubmited && (
        <h5 className="w-100 my-5 text-black" >{t('mail_sent')}</h5>
      )}
    </div>
  )
}
export default ContactForm